import logo from './logo.svg';
import './App.css';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    window.UnityLoader.instantiate("unityContainer", "Build/ReleaseBuildFeelCenter.json", { onProgress: window.UnityProgress });

  }, [])

  return (

    <div className="App">
      <header className="App-header">
        <div style={{ width: "100%", backgroundColor: "#FFF", color: "#000", display:"flex", justifyContent:"center",marginBottom:10, position:"relative", top:0}}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "80%" }}>
            <img src="/icon/feel-center-logo.png"></img>
            <div>
              <p style={{ fontSize:12}}>Benvenuto: <b id="name_player" >Loading</b></p>
              <p style={{ fontSize:12, marginTop:5}}>Punteggio Attuale: <b id="total_points" >0</b></p>
            </div>
          </div>
        </div>
        <div id="question_completed" style={{ display: "none" }} ></div>
        <div id="total_points" style={{ display: "none" }}></div>
        <div id="finish_game" style={{ display: "none" }}></div>
        <div id="unityContainer" style={{ margin: "auto", width: "80%", height: "80%" }}></div>
      </header>
    </div>
  );
}

export default App;
